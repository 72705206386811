const AddTablePreSedationTH = {
  unbreakable: true,
  table: {
    widths: ["20%", "20%", "60%"],
    body: [
      [
        {
          text: "รายละเอียดวิธีการใช้ยาระงับความรู้สึก",
          fontSize: 14,
          bold: true,
          colSpan: 3,
        },
        {},
        {},
      ],
      [
        {
          text: "การดมยาสลบ",
          fontSize: 14,
          border: [true, true, true, false],
        },
        {
          text: "ผลที่คาดว่าจะได้รับ",
          fontSize: 14,
        },
        {
          text: "ผู้ป่วยอยู่ในภาวะไม่รู้สึกตัวอาจมีการใส่ท่อช่วยหายใจเข้าในหลอดลม",
          fontSize: 14,
        },
      ],
      [
        {
          text: "",
          fontSize: 14,
          border: [true, false, true, false],
        },
        {
          text: "วิธีการ",
          fontSize: 14,
        },
        {
          text: "ฉีดยาเข้ากระแสเลือด บริหารยาเข้าปอด หรือวิธีอื่นๆ",
          fontSize: 14,
        },
      ],
      [
        {
          text: "",
          fontSize: 14,
          border: [true, false, true, true],
        },
        {
          text: "ความเสี่ยงที่อาจเกิดขึ้น\n(แต่มิได้จำกัดอยู่เพียง)",
          fontSize: 14,
        },
        {
          text: "บาดเจ็บหรือเป็นแผลที่ริมฝีปาก ฟัน หรือกล่องเสียง เสียงแหบ รู้สึกตัวหรือฝัน ระหว่างที่ดมยา อันตรายต่อหลอดเลือด อาเจียน สำลัก ปอดบวม ความดันโลหิตต่ำ",
          fontSize: 14,
        },
      ],
      [
        {
          text: "การใช้ยากล่อมประสาท",
          fontSize: 14,
          border: [true, true, true, false],
        },
        {
          text: "ผลที่คาดว่าจะได้รับ",
          fontSize: 14,
        },
        {
          text: "ลดความกังวลหรือความเจ็บปวด จำเหตุการณ์ได้บางส่วนหรือจำไม่ได้ชั่วคราว",
          fontSize: 14,
        },
      ],
      [
        {
          text: "",
          fontSize: 14,
          border: [true, false, true, false],
        },
        {
          text: "วิธีการ",
          fontSize: 14,
        },
        {
          text: "ฉีดยาเข้ากระแสเลือด บริหารยาเข้าปอด หรือวิธีอื่นๆที่จะทำให้อยู่ในภาวะกึ่งรู้สึกตัว",
          fontSize: 14,
        },
      ],
      [
        {
          text: "",
          fontSize: 14,
          border: [true, false, true, true],
        },
        {
          text: "ความเสี่ยงที่อาจเกิดขึ้น\n(แต่มิได้จำกัดอยู่เพียง)",
          fontSize: 14,
        },
        {
          text: "ภาวะไม่รู้สึกตัว หายใจได้น้อยลง ปากหรือฟันได้รับบาดเจ็บ ยังคงรู้สึกตัว/รู้สึกไม่สุขสบาย สำลัก ความดันโลหิตต่ำ",
          fontSize: 14,
        },
      ],
      [
        {
          text: "บล็อกหลัง",
          fontSize: 14,
          border: [true, true, true, false],
        },
        {
          text: "ผลที่คาดว่าจะได้รับ",
          fontSize: 14,
        },
        {
          text: "ร่างกายบางส่วนสูญเสียความรู้สึกหรือรู้สึกตัวน้อยลง และ/ หรือเคลื่อนไหวไม่ได้ชั่วคราว",
          fontSize: 14,
        },
      ],
      [
        {
          text: "",
          fontSize: 14,
          border: [true, false, true, false],
        },
        {
          text: "วิธีการ",
          fontSize: 14,
        },
        {
          text: "บริหารยาเข้าในช่องน้ำไขสันหลังหรือใกล้ช่องน้ำไขสันหลัง",
          fontSize: 14,
        },
      ],
      [
        {
          text: "",
          fontSize: 14,
          border: [true, false, true, true],
        },
        {
          text: "ความเสี่ยงที่อาจเกิดขึ้น\n(แต่มิได้จำกัดอยู่เพียง)",
          fontSize: 14,
        },
        {
          text: "ปวดศีรษะ ปวดหลัง แพ้ยาชา ติดเชื้อ มีเลือดออกในหรือนอกช่องน้ำไขสันหลัง การทำงานของเส้นประสาทผิดปกติ ชา ปวดหรือกล้ามเนื้อส่วนล่างของร่างกายอ่อนแรงค้างอยู่นานหรือถาวร",
          fontSize: 14,
        },
      ],
      [
        {
          text: "บล็อกเส้นประสาทหลัก/ย่อย",
          fontSize: 14,
          border: [true, true, true, false],
        },
        {
          text: "ผลที่คาดว่าจะได้รับ",
          fontSize: 14,
        },
        {
          text: "แขนหรือขาสูญเสียความรู้สึก และ/ หรือเคลื่อนไหวไม่ได้ชั่วคราว",
          fontSize: 14,
        },
      ],
      [
        {
          text: "",
          fontSize: 14,
          border: [true, false, true, false],
        },
        {
          text: "วิธีการ",
          fontSize: 14,
        },
        {
          text: "ฉีดยาชาเข้าที่บริเวณใกล้กับเส้นประสาท ทำให้ไม่รู้สึกตัว หรือรู้สึกตัวน้อยลง บริเวณที่จะทำการผ่าตัด",
          fontSize: 14,
        },
      ],
      [
        {
          text: "",
          fontSize: 14,
          border: [true, false, true, true],
        },
        {
          text: "ความเสี่ยงที่อาจเกิดขึ้น\n(แต่มิได้จำกัดอยู่เพียง)",
          fontSize: 14,
        },
        {
          text: "ติดเชื้อ แพ้ยาชา เส้นประสาทได้รับบาดเจ็บ ทำให้แขนขาอ่อนแรง หรือชาถาวร เส้นเลือดได้รับบาดเจ็บ การบ็อกไม่ได้ผล เกิดลมรั่วในช่องปอด",
          fontSize: 14,
        },
      ],
      [
        {
          text: "การเฝ้าติดตามการทำงาน ของร่างกายอย่างใกล้ชิด",
          fontSize: 14,
          border: [true, true, true, false],
        },
        {
          text: "ผลที่คาดว่าจะได้รับ",
          fontSize: 14,
        },
        {
          text: "วัดสัญญาณชีพอย่างต่อเนื่อง ทำการแก้ไขสัญญาณชีพที่ผิดปกติหากจำเป็น",
          fontSize: 14,
        },
      ],
      [
        {
          text: "",
          fontSize: 14,
          border: [true, false, true, false],
        },
        {
          text: "วิธีการ",
          fontSize: 14,
        },
        {
          text: "ไม่มีการบริหารยาระงับความรู้สึกใดๆ หรืออาจมีเฉพาะเมื่อจำเป็น",
          fontSize: 14,
        },
      ],
      [
        {
          text: "",
          fontSize: 14,
          border: [true, false, true, true],
        },
        {
          text: "ความเสี่ยงที่อาจเกิดขึ้น\n(แต่มิได้จำกัดอยู่เพียง)",
          fontSize: 14,
        },
        {
          text: "รู้สึกตัวระหว่างทำหัตถการหรือผ่าตัด รู้สึกกังวล และ/ หรือไม่สุขสบายตัว",
          fontSize: 14,
        },
      ],
      [
        {
          text: " ",
          preserveLeadingSpaces: true,
          fontSize: 14,
          colSpan: 3,
          border: [true, true, true, false],
        },
        {},
        {},
      ],
      [
        {
          text: " ",
          preserveLeadingSpaces: true,
          fontSize: 14,
          colSpan: 3,
          border: [true, false, true, false],
        },
        {},
        {},
      ],
      [
        {
          text: " ",
          preserveLeadingSpaces: true,
          fontSize: 14,
          colSpan: 3,
          border: [true, false, true, true],
        },
        {},
        {},
      ],
    ],
  },
};

export default AddTablePreSedationTH;
