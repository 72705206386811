import { PDFDocument, StandardFonts } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { drawTextOnPage, calculateCenterX } from "./StaticPdfUtils";

// PDF Path
const PDF = "/static/pdf/printList/cons_radiofrq_neu_th.pdf";
const Font = "/static/fonts/THSarabunNew-Bold.ttf"; //* กรณีแก้ Font

const FormConsentRadioNeuralTH = async (props: any) => {
  try {
    const existingPdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Loading Custom Font
    const fontUrl = Font;
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);
    const zapFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);

    const pages = pdfDoc.getPages();
    const fontSize = 15; //*

    // Common Text
    const commonTextCoordinates = [
      { text: props.items?.patientFullName, x: 415, y: 756 },
      { text: props.items?.hn, x: 415, y: 742 },
      { text: props.items?.patientEncounter, x: 532, y: 742 },
      { text: props.items?.birthdateAd, x: 424, y: 730 },
      { text: props.items?.age.toString(), x: 523, y: 730 },
      { text: props.items?.formatGender, x: 418, y: 716 },
    ];

    // Page 1
    const firstTextCoordinates = [
      { text: props.items?.formatPatient, x: 140, y: 595 },
      { text: props.items?.formatDoctor || "-", x: 252, y: 572 },
    ];

    // Page 2
    const secondTextCoordinates = [
      {
        text: props.items?.patientFullName,
        x: calculateCenterX(props.items?.patientFullName, customFont, fontSize, 61, 190),
        y: 517
      },
      {
        text: props.items?.doctorFullName || "",
        x: calculateCenterX(props.items?.doctorFullName || "", customFont, fontSize, 61, 190),
        y: 448
      },
    ];

    const secondDateTimeTextCoordinates = [
      { text: props.items?.signedDate, x: 80, y: 402 },
      { text: props.items?.printTime, x: 143, y: 402 },
    ];

    // Print date footer
    const printDateTimeTextCoordinates = [
      { text: `${props.items?.signedDate} [${props.items?.printTime}]`, x: 104, y: 55 },
    ];

    drawTextOnPage(pages[0], [...commonTextCoordinates, ...firstTextCoordinates], customFont, fontSize);
    drawTextOnPage(pages[1], [...commonTextCoordinates, ...secondTextCoordinates], customFont, fontSize);
    drawTextOnPage(pages[1], secondDateTimeTextCoordinates, customFont, 10);
    // Footer
    drawTextOnPage(pages[0], printDateTimeTextCoordinates, customFont, 10);
    drawTextOnPage(pages[1], printDateTimeTextCoordinates, customFont, 10);

    const modifiedPdfBytes = await pdfDoc.save();
    const getAsBase64 = await pdfDoc.saveAsBase64();
    const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
    const modifiedPdfUrl = URL.createObjectURL(blob);

    return { uint8Array: modifiedPdfBytes, blobUrl: modifiedPdfUrl, blobRaw: blob, base64String: getAsBase64 }

  } catch (error) {
    console.error("Error modifying PDF: ", error);
    return {};
  }
};

export default FormConsentRadioNeuralTH;