import { TDocumentDefinitions } from "pdfmake/interfaces";

const formatData = (data: any) => {
  if (!data) {
    return;
  }
  const isEn = data?.reportLanguage === "EN";

  let patientNameTh = data?.patient_name_th.trim() ?? "";
  let patientNameEn = data?.patient_name_en.trim() ?? "";

  // กรณีมีวงเล็บหลังชื่อ เช่น "นายทดสอบ รอบใหม่ (2)" ให้เอาวงเล็บออก
  patientNameTh = patientNameTh.replace(/\s?\(\d+\)/, "").trim();
  patientNameEn = patientNameEn.replace(/\s?\(\d+\)/, "").trim();

  return {
    patient_fullname:
      data?.patient_fullname || (isEn ? (patientNameEn ? patientNameEn : patientNameTh) : patientNameTh) || null,
    patient_fullage:
      data?.patient_fullage || (isEn ? data?.patient_age_en : data?.patient_age_th) || null,
    pediatric_dentists_fullname:
      data?.pediatric_dentists_fullname ||
      (isEn ? data?.pediatric_dentists_name_en : data?.pediatric_dentists_name_th) ||
      null,
    doctor_fullname:
      data?.doctor_fullname || (isEn ? data?.doctor_name_en : data?.doctor_name_th) || null,
  };
};

const FormCudentMedCertPrintList = async (props: any): Promise<TDocumentDefinitions> => {
  console.log(`Yeti CU Med Cert Print List Data; `, props);
  const data = {
    ...props,
    ...formatData(props),
  };

  return {
    pageMargins: [30, 40],
    pageSize: "A4",
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 14,
    },
    info: {
      title: data?.patient_fullname || "Medical Certificate",
      author: data?.pediatric_dentists_fullname || data?.doctor_fullname || "-",
      subject: "Medical Certificate",
      keywords: "Medical Certificate",
    },
    styles: {
      facultyHeader: { fontSize: 17, bold: true },
      clinicHeader: { fontSize: 15 },
      reportHeader: { fontSize: 16, bold: true },
      miniText: { fontSize: 12 },
      fieldValue: { bold: true },
      blankValue: { color: "white" },
    },
    content: [
      // ---------- logo outline
      {
        image: "logoOutline",
        width: 500,
        // height: 500,
        opacity: 0.15,
        absolutePosition: {
          // x: 150,
          x: (595.28 - 500) / 2,
          y: 162,
        },
      },
      // ---------- header
      {
        image: "logochula",
        width: 150,
        alignment: "center",
        margin: [0, 0, 0, 20],
      },
      {
        text: data?.facultyHeader,
        style: "facultyHeader",
        alignment: "center",
      },
      {
        text: data?.division_name,
        style: "clinicHeader",
        alignment: "center",
      },
      {
        columns: [
          { text: "", width: 200 },
          { text: data?.report_name, width: "*", style: "reportHeader", alignment: "center" },
          {
            text: `${data?.codeText}  ${data?.code}`,
            width: 200,
            style: "miniText",
            alignment: "right",
          },
        ],
      },
      { text: data?.createdDate, alignment: "right" },
      // ---------- content
      {
        table: {
          widths: ["auto", "*"],
          body: [
            [
              {
                text: data?.doctorNameText,
                margin: [40, 0, 0, 0],
                border: [false, false, false, false],
              },
              {
                text: data?.doctor_fullname,
                style: "fieldValue",
                border: [false, false, false, true],
                borderColor: ["", "", "", "grey"],
              },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i: number, _node: any) {
            return { dash: { length: 1, space: 1.5 } };
          },
          hLineWidth: function (i: number, _node: any) {
            return 0.5;
          },
        },
      },
      {
        table: {
          widths: ["auto", "*"],
          body: [
            [
              { text: data?.certNoText, border: [false, false, false, false] },
              {
                text: data?.doctor_certificate_no,
                style: "fieldValue",
                border: [false, false, false, true],
                borderColor: ["", "", "", "grey"],
              },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i: number, _node: any) {
            return { dash: { length: 1, space: 1.5 } };
          },
          hLineWidth: function (i: number, _node: any) {
            return 0.5;
          },
        },
      },
      {
        table: {
          widths: ["auto", "*"],
          body: [
            [
              { text: data?.patientNameText, border: [false, false, false, false] },
              {
                text: data?.patient_fullname,
                style: "fieldValue",
                border: [false, false, false, true],
                borderColor: ["", "", "", "grey"],
              },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i: number, _node: any) {
            return { dash: { length: 1, space: 1.5 } };
          },
          hLineWidth: function (i: number, _node: any) {
            return 0.5;
          },
        },
      },
      {
        table: {
          widths: ["auto", "*", "auto", "*"],
          body: [
            [
              { text: data?.patientAgeText, border: [false, false, false, false] },
              {
                text: data?.patient_fullage,
                style: "fieldValue",
                border: [false, false, false, true],
                borderColor: ["", "", "", "grey"],
              },
              { text: data?.patientHNText, border: [false, false, false, false] },
              {
                text: data?.hn,
                style: "fieldValue",
                border: [false, false, false, true],
                borderColor: ["", "", "", "grey"],
              },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i: number, _node: any) {
            return { dash: { length: 1, space: 1.5 } };
          },
          hLineWidth: function (i: number, _node: any) {
            return 0.5;
          },
        },
      },
      {
        table: {
          widths: ["auto", "*"],
          body: [
            [
              { text: data?.citizenNoText, border: [false, false, false, false] },
              {
                text: data?.patient_citizen_no,
                style: "fieldValue",
                border: [false, false, false, true],
                borderColor: ["", "", "", "grey"],
              },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i: number, _node: any) {
            return { dash: { length: 1, space: 1.5 } };
          },
          hLineWidth: function (i: number, _node: any) {
            return 0.5;
          },
        },
      },
      {
        table: {
          widths: ["auto", "*"],
          body: [
            [
              { text: data?.createdDateText, border: [false, false, false, false] },
              {
                text: data?.createdDate,
                style: "fieldValue",
                border: [false, false, false, true],
                borderColor: ["", "", "", "grey"],
              },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i: number, _node: any) {
            return { dash: { length: 1, space: 1.5 } };
          },
          hLineWidth: function (i: number, _node: any) {
            return 0.5;
          },
        },
      },
      // diagnosis
      data?.diagnosis?.length > 80
        ? {
            text: [
              { text: data?.diagnosisText + "  " },
              {
                text: data?.diagnosis,
                style: "fieldValue",
                lineHeight: 1,
                decoration: "underline",
                decorationStyle: "dotted",
                decorationColor: "grey",
              },
            ],
          }
        : [
            {
              table: {
                widths: ["auto", "*"],
                body: [
                  [
                    { text: data?.diagnosisText, border: [false, false, false, false] },
                    {
                      text: data?.diagnosis,
                      style: "fieldValue",
                      border: [false, false, false, true],
                      borderColor: ["", "", "", "grey"],
                    },
                  ],
                ],
              },
              layout: {
                hLineStyle: function (i: number, _node: any) {
                  return { dash: { length: 1, space: 1.5 } };
                },
                hLineWidth: function (i: number, _node: any) {
                  return 0.5;
                },
              },
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      text: ".",
                      style: "blankValue",
                      border: [false, false, false, true],
                      borderColor: ["", "", "", "grey"],
                    },
                  ],
                ],
              },
              layout: {
                hLineStyle: function (i: number, _node: any) {
                  return { dash: { length: 1, space: 1.5 } };
                },
                hLineWidth: function (i: number, _node: any) {
                  return 0.5;
                },
              },
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      text: ".",
                      style: "blankValue",
                      border: [false, false, false, true],
                      borderColor: ["", "", "", "grey"],
                    },
                  ],
                ],
              },
              layout: {
                hLineStyle: function (i: number, _node: any) {
                  return { dash: { length: 1, space: 1.5 } };
                },
                hLineWidth: function (i: number, _node: any) {
                  return 0.5;
                },
              },
            },
          ],
      // treatment
      data?.treatment?.length > 80
        ? {
            text: [
              { text: data?.treatmentText + "  " },
              {
                text: data?.treatment,
                style: "fieldValue",
                lineHeight: 1,
                decoration: "underline",
                decorationStyle: "dotted",
                decorationColor: "grey",
              },
            ],
          }
        : [
            {
              table: {
                widths: ["auto", "*"],
                body: [
                  [
                    { text: data?.treatmentText, border: [false, false, false, false] },
                    {
                      text: data?.treatment,
                      style: "fieldValue",
                      border: [false, false, false, true],
                      borderColor: ["", "", "", "grey"],
                    },
                  ],
                ],
              },
              layout: {
                hLineStyle: function (i: number, _node: any) {
                  return { dash: { length: 1, space: 1.5 } };
                },
                hLineWidth: function (i: number, _node: any) {
                  return 0.5;
                },
              },
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      text: ".",
                      style: "blankValue",
                      border: [false, false, false, true],
                      borderColor: ["", "", "", "grey"],
                    },
                  ],
                ],
              },
              layout: {
                hLineStyle: function (i: number, _node: any) {
                  return { dash: { length: 1, space: 1.5 } };
                },
                hLineWidth: function (i: number, _node: any) {
                  return 0.5;
                },
              },
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      text: ".",
                      style: "blankValue",
                      border: [false, false, false, true],
                      borderColor: ["", "", "", "grey"],
                    },
                  ],
                ],
              },
              layout: {
                hLineStyle: function (i: number, _node: any) {
                  return { dash: { length: 1, space: 1.5 } };
                },
                hLineWidth: function (i: number, _node: any) {
                  return 0.5;
                },
              },
            },
          ],
      // detail
      data?.detail?.length > 80
        ? {
            text: [
              { text: data?.detailText + "  " },
              {
                text: data?.detail,
                style: "fieldValue",
                lineHeight: 1,
                decoration: "underline",
                decorationStyle: "dotted",
                decorationColor: "grey",
              },
            ],
          }
        : [
            {
              table: {
                widths: ["auto", "*"],
                body: [
                  [
                    { text: data?.detailText, border: [false, false, false, false] },
                    {
                      text: data?.detail,
                      style: "fieldValue",
                      border: [false, false, false, true],
                      borderColor: ["", "", "", "grey"],
                    },
                  ],
                ],
              },
              layout: {
                hLineStyle: function (i: number, _node: any) {
                  return { dash: { length: 1, space: 1.5 } };
                },
                hLineWidth: function (i: number, _node: any) {
                  return 0.5;
                },
              },
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      text: ".",
                      style: "blankValue",
                      border: [false, false, false, true],
                      borderColor: ["", "", "", "grey"],
                    },
                  ],
                ],
              },
              layout: {
                hLineStyle: function (i: number, _node: any) {
                  return { dash: { length: 1, space: 1.5 } };
                },
                hLineWidth: function (i: number, _node: any) {
                  return 0.5;
                },
              },
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      text: ".",
                      style: "blankValue",
                      border: [false, false, false, true],
                      borderColor: ["", "", "", "grey"],
                    },
                  ],
                ],
              },
              layout: {
                hLineStyle: function (i: number, _node: any) {
                  return { dash: { length: 1, space: 1.5 } };
                },
                hLineWidth: function (i: number, _node: any) {
                  return 0.5;
                },
              },
            },
          ],
      // ---------- signature
      {
        table: {
          widths: ["auto", "*", 100, "auto", "*"],
          body: [
            [
              data?.pediatric_dentists_fullname
                ? {
                    text: data?.signText,
                    border: [false, false, false, false],
                  }
                : { text: "", border: [false, false, false, false] },

              data?.pediatric_dentists_fullname
                ? {
                    text: "",
                    border: [false, false, false, true],
                    borderColor: ["", "", "", "grey"],
                  }
                : { text: "", border: [false, false, false, false] },

              {
                text: ".",
                style: "blankValue",
                border: [false, false, false, false],
              },
              {
                text: data?.signText,
                border: [false, false, false, false],
              },
              {
                text: "",
                border: [false, false, false, true],
                borderColor: ["", "", "", "grey"],
              },
            ],
            [
              {
                text: "",
                border: [false, false, false, false],
              },

              data?.pediatric_dentists_fullname
                ? {
                    text: `(${data?.pediatric_dentists_fullname})`,
                    border: [false, false, false, false],
                    alignment: "center",
                  }
                : {
                    text: "",
                    border: [false, false, false, false],
                  },

              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "",
                border: [false, false, false, false],
              },
              data?.doctor_fullname
                ? {
                    text: `(${data?.doctor_fullname})`,
                    border: [false, false, false, false],
                    alignment: "center",
                  }
                : {
                    text: "",
                    border: [false, false, false, false],
                  },
            ],
            [
              {
                text: "",
                border: [false, false, false, false],
              },

              data?.pediatric_dentists_fullname
                ? {
                    text: data?.pediatricSignText,
                    border: [false, false, false, false],
                    alignment: "center",
                  }
                : {
                    text: "",
                    border: [false, false, false, false],
                  },

              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: data?.doctorSignText,
                border: [false, false, false, false],
                alignment: "center",
              },
            ],
          ],
        },
        layout: {
          hLineStyle: function (i: number, _node: any) {
            return { dash: { length: 1, space: 1.5 } };
          },
          hLineWidth: function (i: number, _node: any) {
            return 0.5;
          },
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      return {
        columns: [{ text: data?.footer, alignment: "left", margin: [10, 0, 0, 0] }],
      };
    },
    images: {
      logochula: origin + "/static/images/logoform/CU/FormDentistCertificate.png",
      logoOutline: origin + "/static/images/logoform/CU/FormDentistCertificate_Outline.png",
    },
  };
};

export default FormCudentMedCertPrintList;
