import { PDFDocument, StandardFonts } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { drawTextOnPage, calculateCenterX } from "./StaticPdfUtils";

// PDF Path
const PDF = "/static/pdf/printList/cons_spinal_en.pdf";
const Font = "/static/fonts/THSarabunNew-Bold.ttf"; //* กรณีแก้ Font

const FormConsentSpinalEN = async (props: any) => {
  try {
    const existingPdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Loading Custom Font
    const fontUrl = Font;
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);
    const zapFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);

    const pages = pdfDoc.getPages();
    const fontSize = 15; //*

    // Common Text
    const commonTextCoordinates = [
      { text: props.items?.patientFullName, x: 440, y: 765 },
      { text: props.items?.hn, x: 428, y: 753 },
      { text: props.items?.patientEncounter, x: 554, y: 753 },
      // { text: props.items?.birthdateAd, x: 463, y: 740 },
      { text: props.items?.age.toString(), x: 540, y: 740 },
      { text: props.items?.formatGender, x: 433, y: 728 },
    ];

    const commonBithDateCoordinate = [
      { text: props.items?.birthdateAd, x: 463, y: 740 },
    ];

    // Page 1
    const firstTextCoordinates = [
      { text: props.items?.formatPatient, x: 134, y: 600 },
      { text: props.items?.formatDoctor || "-", x: 364, y: 586 },
    ];

    // Page 3
    const thirdTextCoordinates = [
      { 
        text: props.items?.patientFullName,
        x: calculateCenterX(props.items?.patientFullName, customFont, fontSize, 94, 234),
        y: 323
      },
      { text: props.items?.doctorFullName || "",
        x: calculateCenterX(props.items?.doctorFullName || "", customFont, fontSize, 98, 236),
        y: 254
      },
    ];

    const thirdDateTimeTextCoordinates = [
      { text: props.items?.signedDate, x: 104, y: 210 },
      { text: props.items?.printTime, x: 202, y: 210 },
    ];

    // Print date footer
    const printDateTimeTextCoordinates = [
      { text: `${props.items?.signedDate} [${props.items?.printTime}]`, x: 116, y: 38 },
    ];

    drawTextOnPage(pages[0], [...commonTextCoordinates, ...firstTextCoordinates], customFont, fontSize);
    drawTextOnPage(pages[0], commonBithDateCoordinate, customFont, 11);
    drawTextOnPage(pages[1], commonTextCoordinates, customFont, fontSize);
    drawTextOnPage(pages[1], commonBithDateCoordinate, customFont, 11);
    drawTextOnPage(pages[2], [...commonTextCoordinates, ...thirdTextCoordinates], customFont, fontSize);
    drawTextOnPage(pages[2], commonBithDateCoordinate, customFont, 11);
    drawTextOnPage(pages[2], thirdDateTimeTextCoordinates, customFont, 10);
    // Footer
    drawTextOnPage(pages[0], printDateTimeTextCoordinates, customFont, 10);
    drawTextOnPage(pages[1], printDateTimeTextCoordinates, customFont, 10);
    drawTextOnPage(pages[2], printDateTimeTextCoordinates, customFont, 10);

    const modifiedPdfBytes = await pdfDoc.save();
    const getAsBase64 = await pdfDoc.saveAsBase64();
    const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
    const modifiedPdfUrl = URL.createObjectURL(blob);

    return { uint8Array: modifiedPdfBytes, blobUrl: modifiedPdfUrl, blobRaw: blob, base64String: getAsBase64 }

  } catch (error) {
    console.error("Error modifying PDF: ", error);
    return {};
  }
};

export default FormConsentSpinalEN;