import React, {
  useState,
  useEffect,
  CSSProperties,
  MutableRefObject,
  useRef,
  useImperativeHandle,
  useMemo,
  useCallback,
} from "react";
import moment from "moment";
import { SemanticICONS } from "semantic-ui-react";

// UX
import DropdownOptions from "../../appcon/common/DropdownOptions";
import { useIntl } from "react-intl";

type TimeComboBoxProps = {
  id?: string;
  style?: CSSProperties;
  inputStyle?: CSSProperties;
  // data
  placeholder?: string;
  icon?: SemanticICONS;
  defaultValue?: string;
  interval?: number;
  minHour?: number;
  maxHour?: number;
  // config
  disabled?: boolean;
  noMinWidth?: boolean;
  fluid?: boolean;
  // callback
  onTextChange?: (time: string) => any;
};

const IconStyle = { top: "0.7rem" };

const TimeComboBox = React.forwardRef<any, TimeComboBoxProps>((props, ref) => {
  const [timeList, setTimeList] = useState([]);
  const [time, setTime] = useState("");

  const inputRef = useRef(null) as MutableRefObject<any>;

  useImperativeHandle<any, any>(ref, () => ({
    setValue: (value: string) => {
      setTime(value);
      generateTimeOptions(value);
    },
    getValue: () => time,
    inputRef: inputRef,
  }));

  // Effect
  useEffect(() => {
    generateTimeOptions();
  }, []);

  useEffect(() => {
    let value = props.defaultValue || "";

    if (time !== value) {
      const reg = /^\d{2}:\d{2}:\d{2}$/g;

      if (value.search(reg) >= 0) {
        value = value.replace(/:\d{2}$/g, "");
      }

      setTime(value);
      generateTimeOptions(value);
    }
  }, [props.defaultValue, time]);

  // Callback
  const handleChange = useCallback(
    (e: any, v: any) => {
      if (v.value !== time) {
        handleChangeTime(v.value);
      }
    },
    [time]
  );

  // Memo
  const timeOptions = useMemo(() => {
    return timeList.map((time, index) => ({
      key: index + 1,
      value: time,
      text: time,
    }));
  }, [timeList]);

  // Handler
  const generateTimeOptions = (time = "") => {
    let [hour, min] = time.split(":");

    min = min || ":00";

    const date = moment().format("MM/DD/YYYY");

    let timeList: string[] = [];
    let start = moment(
      date + " " + ("0" + props.minHour).slice(-2) + min,
      "MM/DD/YYYY HH:mm"
    );

    let end = moment(
      date + " " + ("0" + props.maxHour).slice(-2) + min,
      "MM/DD/YYYY HH:mm"
    );

    if (props.maxHour == 23) {
      end = moment(
        date + " " + ("0" + props.maxHour).slice(-2) + ":59",
        "MM/DD/YYYY HH:mm"
      );
    } else if ((props.maxHour as number) > 23) {
      const remainHours = (props.maxHour as number) - 23;

      end = moment(
        date + " " + ("0" + remainHours).slice(-2) + min,
        "MM/DD/YYYY HH:mm"
      );
      end.add("days", 1);
    }

    while (start <= end) {
      const time = moment(start).format("HH:mm");
      timeList.push(time);
      start = moment(start).add(props.interval, "minutes");
    }

    if (time) {
      const [prev, next] = timeList.reduce(
        (result, value) => {
          result[Intl.Collator().compare(time, value) > 0 ? 1 : 0].push(value);
          return result;
        },
        [[] as string[], [] as string[]]
      );

      timeList = [...prev, ...next];
    }

    setTimeList([...timeList] as any);
  };

  const handleChangeTime = (time: string) => {
    // console.log("handleChangeTime", time);
    setTime(time);
    props.onTextChange?.(time);
    generateTimeOptions(time);
  };

  return (
    <DropdownOptions
      id={props.id}
      ref={inputRef}
      value={time}
      placeholder={props.placeholder}
      options={timeOptions}
      style={props.style}
      inputStyle={props.inputStyle}
      icon={props.icon}
      iconStyle={IconStyle}
      // config
      disabled={props.disabled}
      noMinWidth={props.noMinWidth}
      fluid={props.fluid}
      search={true}
      formatTimeOnBlur={true}
      // callback
      onChange={handleChange}
    />
  );
});

TimeComboBox.defaultProps = {
  style: {},
  inputStyle: {},
  // data
  placeholder: "Choose Time",
  interval: 30,
  minHour: 0,
  maxHour: 30,
  icon: "time",
  // config
  disabled: false,
  // callback
  onTextChange: () => {},
} as TimeComboBoxProps;

export default React.memo(TimeComboBox);
