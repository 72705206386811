import { PDFDocument, StandardFonts } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { drawTextOnPage, calculateCenterX } from "./StaticPdfUtils";

// PDF Path
const PDF = "/static/pdf/printList/cons_plasma_th.pdf";
const Font = "/static/fonts/THSarabunNew-Bold.ttf"; //* กรณีแก้ Font

const FormConsentPlasmaTH = async (props: any) => {
  try {
    const existingPdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Loading Custom Font
    const fontUrl = Font;
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);
    const zapFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);

    const pages = pdfDoc.getPages();
    const fontSize = 15; //*

    // Common Text
    const commonTextCoordinates = [
      { text: props.items?.patientFullName, x: 428, y: 758 },
      { text: props.items?.hn, x: 428, y: 747 },
      { text: props.items?.patientEncounter, x: 538, y: 747 },
      { text: props.items?.birthdateAd, x: 437, y: 737 },
      { text: props.items?.age.toString(), x: 528, y: 737 },
      { text: props.items?.formatGender, x: 430, y: 725 },
    ];

    // Page 1
    const firstTextCoordinates = [
      { text: props.items?.formatPatient, x: 164, y: 596 },
      { text: props.items?.formatDoctor || "-", x: 292, y: 575 },
    ];

    // Page 2
    const secondTextCoordinates = [
      {
        text: props.items?.patientFullName,
        x: calculateCenterX(props.items?.patientFullName, customFont, fontSize, 84, 221),
        y: 488
      },
      {
        text: props.items?.doctorFullName || "",
        x: calculateCenterX(props.items?.doctorFullName || "", customFont, fontSize, 87, 223),
        y: 426
      },
    ];

    const secondDateTimeTextCoordinates = [
      { text: props.items?.signedDate, x: 108, y: 384 },
      { text: props.items?.printTime, x: 185, y: 384 },
    ];

    // Print date footer
    const printDateTimeTextCoordinates = [
      { text: `${props.items?.signedDate} [${props.items?.printTime}]`, x: 88, y: 53 },
    ];

    drawTextOnPage(pages[0], commonTextCoordinates, customFont, 12);
    drawTextOnPage(pages[0], firstTextCoordinates, customFont, fontSize);
    drawTextOnPage(pages[1], [...commonTextCoordinates, ...secondTextCoordinates], customFont, fontSize);
    drawTextOnPage(pages[1], secondDateTimeTextCoordinates, customFont, 10);
    // Footer
    drawTextOnPage(pages[0], printDateTimeTextCoordinates, customFont, 10);
    drawTextOnPage(pages[1], printDateTimeTextCoordinates, customFont, 10);

    const modifiedPdfBytes = await pdfDoc.save();
    const getAsBase64 = await pdfDoc.saveAsBase64();
    const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
    const modifiedPdfUrl = URL.createObjectURL(blob);

    return { uint8Array: modifiedPdfBytes, blobUrl: modifiedPdfUrl, blobRaw: blob, base64String: getAsBase64 }

  } catch (error) {
    console.error("Error modifying PDF: ", error);
    return {};
  }
};

export default FormConsentPlasmaTH;