const AddTablePreSedationEN = {
  unbreakable: true,
  table: {
    widths: ["20%", "20%", "60%"],
    body: [
      [
        {
          text: "Detail of anesthetic technique",
          fontSize: 13,
          bold: true,
          colSpan: 3,
        },
        {},
        {},
      ],
      [
        {
          text: "General Anesthesia",
          fontSize: 13,
          border: [true, true, true, false],
        },
        {
          text: "Expected Result",
          fontSize: 13,
        },
        {
          text: "Total unconscious state, placement of a tube into the windpipe.",
          fontSize: 13,
        },
      ],
      [
        {
          text: "",
          fontSize: 13,
          border: [true, false, true, false],
        },
        {
          text: "Technique",
          fontSize: 13,
        },
        {
          text: "Drug injected into the bloodstream, breathed into the lungs, or by other routes.",
          fontSize: 13,
        },
      ],
      [
        {
          text: "",
          fontSize: 13,
          border: [true, false, true, true],
        },
        {
          text: "Risks (include but not limitted to)",
          fontSize: 13,
        },
        {
          text: "Injury to mouth, teeth or glottis, hoarseness, awareness during anesthesia, injury to blood vessels, vomiting, aspiration, pneumonia, low blood pressure.",
          fontSize: 13,
        },
      ],
      [
        {
          text: "Sedation",
          fontSize: 13,
          border: [true, true, true, false],
        },
        {
          text: "Expected Result",
          fontSize: 13,
        },
        {
          text: "Reduced anxiety and pain, partial or total amnesia.",
          fontSize: 13,
        },
      ],
      [
        {
          text: "",
          fontSize: 13,
          border: [true, false, true, false],
        },
        {
          text: "Technique",
          fontSize: 13,
        },
        {
          text: "Drug injected into the bloodstream, breathed into the lungs, or by other routes, producing a semi-conscious state.",
          fontSize: 13,
        },
      ],
      [
        {
          text: "",
          fontSize: 13,
          border: [true, false, true, true],
        },
        {
          text: "Risks (include but not limitted to)",
          fontSize: 13,
        },
        {
          text: "An unconscious state, depressed breathing, injury to mouth or teeth, awareness and/or discomfort, aspiration, low blood pressure.",
          fontSize: 13,
        },
      ],
      [
        {
          text: "Spinal and/ or Epidural Analgesia/ Anesthesia",
          fontSize: 13,
          border: [true, true, true, false],
        },
        {
          text: "Expected Result",
          fontSize: 13,
        },
        {
          text: "Temporary decreased or loss of feeling and/ or movement to some part of the body.",
          fontSize: 13,
        },
      ],
      [
        {
          text: "",
          fontSize: 13,
          border: [true, false, true, false],
        },
        {
          text: "Technique",
          fontSize: 13,
        },
        {
          text: "Drug injected through a needle/ catheter placed either directly into the fluid of the spinal canal or immediately outside the spinal canal.",
          fontSize: 13,
        },
      ],
      [
        {
          text: "",
          fontSize: 13,
          border: [true, false, true, true],
        },
        {
          text: "Risks (include but bot limitted to)",
          fontSize: 13,
        },
        {
          text: "Headache, backache, local anesthetic intoxication, infection, bleeding in or outside spinal canal, persistent weakness, numbness, residual pain, injury to blood vessels, “total spinal”.",
          fontSize: 13,
        },
      ],
      [
        {
          text: "Major/ Minor Nerve Block",
          fontSize: 13,
          border: [true, true, true, false],
        },
        {
          text: "Expected Result",
          fontSize: 13,
        },
        {
          text: "Temporary loss of feeling and/ or movement of specific limb or area.",
          fontSize: 13,
        },
      ],
      [
        {
          text: "",
          fontSize: 13,
          border: [true, false, true, false],
        },
        {
          text: "Technique",
          fontSize: 13,
        },
        {
          text: "Drug injected near nerves providing loss of sensation to the area of the operation.",
          fontSize: 13,
        },
      ],
      [
        {
          text: "",
          fontSize: 13,
          border: [true, false, true, true],
        },
        {
          text: "Risks (include but not limitted to)",
          fontSize: 13,
        },
        {
          text: "Infection, local anesthetic intoxication, persistent numbness or weakness, injury to blood vessels, failed block, air in the chest.",
          fontSize: 13,
        },
      ],
      [
        {
          text: "Monitored anesthesia care",
          fontSize: 13,
          border: [true, true, true, false],
        },
        {
          text: "Expected Result",
          fontSize: 13,
        },
        {
          text: "Measurement of vital signs, availability of anesthesia provider for further intervention.",
          fontSize: 13,
        },
      ],
      [
        {
          text: "",
          fontSize: 13,
          border: [true, false, true, false],
        },
        {
          text: "Technique",
          fontSize: 13,
        },
        {
          text: "None",
          fontSize: 13,
        },
      ],
      [
        {
          text: "",
          fontSize: 13,
          border: [true, false, true, true],
        },
        {
          text: "Risks (include but not limitted to)",
          fontSize: 13,
        },
        {
          text: "Increased awareness, anxiety and/ or discomfort.",
          fontSize: 13,
        },
      ],
      [
        {
          text: " ",
          preserveLeadingSpaces: true,
          fontSize: 13,
          colSpan: 3,
          border: [true, true, true, false],
        },
        {},
        {},
      ],
      [
        {
          text: " ",
          preserveLeadingSpaces: true,
          fontSize: 13,
          colSpan: 3,
          border: [true, false, true, false],
        },
        {},
        {},
      ],
      [
        {
          text: " ",
          preserveLeadingSpaces: true,
          fontSize: 13,
          colSpan: 3,
          border: [true, false, true, true],
        },
        {},
        {},
      ],
    ],
  },
};

export default AddTablePreSedationEN;
